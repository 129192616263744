
import moment from 'moment';
import { getHoursMinutesFormat } from 'services/utils';
import { RANGE_LABEL_3_YEARS, RANGE_LABEL_MONTH, SENSOR_GRAPH_RANGES, EXTENDED_SENSOR_GRAPH_RANGES, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { fields } from '../../rules/rules-detail/rules-detail.controller';
import { States } from '../../../../app.router';

/* @ngInject */
export default class ActiveAlertsController {
    
    constructor($state, $scope, AlertsAndRulesService, SensorService, FeatureFlags, UserPreferencesManager) {
        this.$state = $state;
        this.$scope = $scope;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.FeatureFlags = FeatureFlags;
        this.UserPreferencesManager = UserPreferencesManager;
    }

    get hasLongTermStorageFeatureFlag() {
        return this.FeatureFlags.isActive('sensor_long_term_storage')
    }

    get availableRanges() {
        if (this.hasLongTermStorageFeatureFlag) {
            return SENSOR_GRAPH_RANGES;
        }

        return SENSOR_GRAPH_RANGES.filter(range => !EXTENDED_SENSOR_GRAPH_RANGES.includes(range));
    }

    get greatestRangeLabel() {
        return this.hasLongTermStorageFeatureFlag ? RANGE_LABEL_3_YEARS : RANGE_LABEL_MONTH;
    }

    get unavailableRanges() {
        if (this.hasLongTermStorageFeatureFlag) {
            return [];
        }

        return EXTENDED_SENSOR_GRAPH_RANGES;
    }

    get alertIcon() {
        let iconName = this.alert.triggered.trigger.field
        
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }

        if (iconName === 'connectionStatus') {
            iconName = this.alert.device.typeIcon
        }

        if (iconName === 'objectPresent') {
            return 'proximity'
        }

        if (iconName === 'waterPresent') {
            return 'waterDetector'
        }

        return iconName
    }

    get inputPlaceholderForCurrentUserAction() {
        if (this.currentUserAction === ALERT_EVENT_TYPES.ACKNOWLEDGED) {
            return 'Add a comment (optional)';
        }

        if (this.currentUserAction === ALERT_EVENT_TYPES.CORRECTIVE_ACTION) {
            return 'Add a corrective action';
        }

        if (this.currentUserAction === ALERT_EVENT_TYPES.COMMENT) {
            return 'Add a comment';
        }

        return '';
    }

    get lowerUpperThresholds() {
        return `${this.alert.triggered.trigger.range.lower},${this.alert.triggered.trigger.range.upper}`
    }

    get timeToResolvedDuration() {
        const triggeredEvent = this.alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED);
        const resolvedEvent = this.alert.events.find(event => event.type === ALERT_EVENT_TYPES.RESOLVED);
        if (!triggeredEvent || !resolvedEvent) {
            return null;
        }

        const triggeredTime = moment(triggeredEvent.createTime);
        const resolvedTime = moment(resolvedEvent.createTime);
        return moment.duration(resolvedTime.diff(triggeredTime)).humanize();
    }
    
    $onInit() {
        this.fields = fields;
        this.ranges = SENSOR_GRAPH_RANGES
        this.currentUserAction = null
        this.currentUserActionText = ''
        this.showOptions = false

        this.popoverHeader = ''
        this.popoverTitle = ''
        this.popoverSubject = ''
        this.popoverContent = ''

        this.convertEventsForTimeline()
    }
    
    convertEventsForTimeline() {    
        // Convert createTime to human readable format
        this.alert.events.forEach(event => {
            event.createTimeFormatted = moment(event.createTime).format(`dddd, MMM D, ${getHoursMinutesFormat()}`);
            event.timeSince = moment(event.createTime).fromNow();
            if (event.timeSince === 'a few seconds ago') {
                event.timeSince = 'just now';
            }

            // Convert type to a more friendly format
            switch (event.type) {
                case ALERT_EVENT_TYPES.TRIGGERED:
                    event.typeFormatted = 'Open - Active Alert';
                    break;
                case ALERT_EVENT_TYPES.NOTIFICATION_DELIVERY:
                    event.typeFormatted = event.notificationDelivery.title;
                    break;
                case ALERT_EVENT_TYPES.ESCALATED:
                    event.typeFormatted = `Escalated to new recipients`;
                    break;
                case ALERT_EVENT_TYPES.ACKNOWLEDGED:
                    event.typeFormatted = `Acknowledged by ${event.acknowledged.account.displayName || event.acknowledged.account.email}`;
                    break;
                case ALERT_EVENT_TYPES.UNACKNOWLEDGED:
                    event.typeFormatted = 'Alert re-opened due to inactivity';
                    break;
                case ALERT_EVENT_TYPES.CORRECTIVE_ACTION:
                    event.typeFormatted = `Corrective Action by ${event.correctiveAction.account.displayName || event.correctiveAction.account.email}`;
                    break;
                case ALERT_EVENT_TYPES.COMMENT:
                    event.typeFormatted = `Comment by ${event.comment.account.displayName || event.comment.account.email}`;
                    break;
                case ALERT_EVENT_TYPES.RESOLVED:
                    event.typeFormatted = 'Resolved';
                    break;
                case ALERT_EVENT_TYPES.ARCHIVED:
                    if (event.archived.description === 'Archived by user') {
                        event.typeFormatted = `Archived by ${event.archived.account.displayName || event.archived.account.email}`;
                    } else {
                        event.typeFormatted = event.archived.description;
                    }
                    break;
                default:
                    event.typeFormatted = event.type;
            }
        })        

    }

    showEventPopover(eventElement, alertEvent) {

        if (alertEvent.type !== ALERT_EVENT_TYPES.NOTIFICATION_DELIVERY) {
            return 
        }

        if (alertEvent.notificationDelivery.action.type !== 'EMAIL' && alertEvent.notificationDelivery.action.type !== 'SMS') {
            return 
        }

        if (alertEvent.notificationDelivery.action.type === 'EMAIL') {
            this.popoverHeader = 'The following email was sent:';
            this.popoverTitle = `${alertEvent.notificationDelivery.action.email.recipients[0]}`;
            this.popoverSubject = alertEvent.notificationDelivery.action.email.subject;
            this.popoverContent = alertEvent.notificationDelivery.action.email.body;
        }
        if (alertEvent.notificationDelivery.action.type === 'SMS') {
            this.popoverHeader = 'The following SMS was sent:';
            this.popoverTitle = `${alertEvent.notificationDelivery.action.sms.recipients[0]}`;
            this.popoverSubject = '';
            this.popoverContent = alertEvent.notificationDelivery.action.sms.body;
        }

        const popover = document.getElementById(this.alert.events[0].eventId); // Need to have a unique ID for each alert, therefore using the first event ID
        const elementTarget = eventElement.currentTarget;

        popover.classList.remove('hidden');
        
        // Wait for the popover to render before calculating position
        setTimeout(() => {
            const containerRect = elementTarget.offsetParent.getBoundingClientRect();
            const eventRect = elementTarget.getBoundingClientRect();

            // Now that the content is rendered, clientHeight should be accurate
            const topPosition = eventRect.top - containerRect.top - popover.clientHeight / 2 + 16;

            popover.style.top = `${topPosition}px`;
            popover.style.left = `312px`;
        }, 0);

    }

    hideEventPopover() {
        const popover = document.getElementById(this.alert.events[0].eventId); // Need to have a unique ID for each alert, therefore using the first event ID
        popover.classList.add('hidden');
    }

    setRange(range) {
        this.alert.currentRange = range;
        this.alert.showRangeDropdown = false;
    }

    goToAlertRule() {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            ruleId: this.alert.rule.split('/')[3]
        });
    }

    goToDevice() {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: this.alert.device.name.split('/')[1],
            sensorId: this.alert.device.name.split('/')[3]
        });
    }

    alertHasCorrectiveAction() {
        return this.alert.events.some(event => event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION);
    }


    startUserAction(action) {
        this.currentUserAction = action
    }

    saveCurrentAction() {
        if (this.currentUserAction === ALERT_EVENT_TYPES.ACKNOWLEDGED) {
            this.acknowledgeAlert()
        }

        if (this.currentUserAction === ALERT_EVENT_TYPES.CORRECTIVE_ACTION && this.currentUserActionText !== '') {
            this.addCorrectiveAction()
        }

        if (this.currentUserAction === ALERT_EVENT_TYPES.COMMENT && this.currentUserActionText !== '') {
            this.addComment()
        }
    }

    acknowledgeAlert() {
        this.AlertsAndRulesService.acknowledgeAlert(this.alert, this.currentUserActionText)
            .then(alert => {
                
                // Unable to just replace the alert object because it will break the reference for the graph
                this.alert.events = alert.events
                this.alert.hasBeenAcknowledged = alert.hasBeenAcknowledged
                this.alert.hasCorrectiveAction = alert.hasCorrectiveAction
                this.alert.unacknowledgesAtTime = alert.unacknowledgesAtTime
                this.alert.escalatesAtTime = alert.escalatesAtTime
                this.alert.currentEscalationLevel = alert.currentEscalationLevel
                this.alert.updateTime = alert.updateTime
                this.alert.status = alert.status
                this.convertEventsForTimeline()
                this.cancelUserAction()

                this.$scope.$applyAsync()
            });
    }

    timeToUnacknowledged() {

        // Not using humanize() because it will round up to the nearest hour
        const unackTime = moment(this.alert.unacknowledgesAtTime);
        const now = moment();
        const duration = moment.duration(unackTime.diff(now));
    
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
    
        const parts = [];
        if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
        if (minutes > 0) parts.push(`${minutes} min${minutes !== 1 ? 's' : ''}`);
    
        return parts.length > 0 ? parts.join(' ') : 'less than a minute';
    }

    timeToEscalation() {
        const escalationTime = moment(this.alert.escalatesAtTime);
        const now = moment();
        const duration = moment.duration(escalationTime.diff(now));
    
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
    
        const parts = [];
        if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
        if (minutes > 0) parts.push(`${minutes} min${minutes !== 1 ? 's' : ''}`);
    
        return parts.length > 0 ? parts.join(' ') : 'less than a minute';
    }
    

    addComment() {
        this.AlertsAndRulesService.addComment(this.alert, this.currentUserActionText)
            .then(alert => {
                this.alert.events = alert.events
                this.convertEventsForTimeline()
                this.cancelUserAction()

                this.$scope.$applyAsync()
            });
    }

    addCorrectiveAction() {
        this.AlertsAndRulesService.addCorrectiveAction(this.alert, this.currentUserActionText)
            .then(alert => {

                // Unable to just replace the alert object because it will break the reference for the graph
                this.alert.events = alert.events
                this.alert.hasCorrectiveAction = alert.hasCorrectiveAction
                this.alert.unacknowledgesAtTime = alert.unacknowledgesAtTime
                this.alert.escalatesAtTime = alert.escalatesAtTime
                this.alert.currentEscalationLevel = alert.currentEscalationLevel
                this.alert.updateTime = alert.updateTime
                this.alert.status = alert.status
                
                this.convertEventsForTimeline()
                this.cancelUserAction()

                this.$scope.$applyAsync()
            });
    }

    cancelUserAction() {
        this.currentUserAction = null
        this.currentUserActionText = ''
    }

    archiveAlert() {
        this.AlertsAndRulesService.archiveAlert(this.alert)
            .then(() => {
                this.$state.reload()
            });
    }
 
    $onDestroy() {}

}
