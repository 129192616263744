import moment from 'moment';
import 'moment-timezone';
import {
    SHOW_ORGANIZATION_ALERTS,
    HIDE_ORGANIZATION_ALERTS
} from 'services/StudioEvents';
import { getHoursMinutesFormat, truncateString, triggerDescription } from 'services/utils';
import { RANGE_LABEL_WEEK, ALERT_EVENT_TYPES } from 'services/charting/constants';
import { CREATE_PROJECT } from 'services/Permissions';
import { States } from '../../app.router';

import TimelineController from '../../modules/alerts/alert-log/timeline/timeline.controller';
import TimelineTemplate from '../../modules/alerts/alert-log/timeline/timeline.html';

/* @ngInject */
export default class OrganizationDetailsController {

    constructor(IAMService, ToastService, ProjectManager, $q, RoleManager, $state, $scope, $rootScope, AlertsAndRulesService, SensorService, DialogService) {
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.$q = $q;
        this.RoleManager = RoleManager;
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.AlertsAndRulesService = AlertsAndRulesService;
        this.SensorService = SensorService;
        this.DialogService = DialogService;
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_PROJECT);
    }
 
    get numberOfOpenAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'TRIGGERED').length;
    }

    get numberOfAcknowledgedAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'ACKNOWLEDGED').length;
    }

    get numberOfMonitoringAlerts() {
        return this.filteredAlerts.filter(alert => alert.status === 'MONITORING').length;
    }

    $onInit() {

        this.$rootScope.$broadcast(SHOW_ORGANIZATION_ALERTS); 

        this.project = this.ProjectManager.currentProject;
        this.organization = this.ProjectManager.currentProject.organization;
        
        this.orgName = `${this.ProjectManager.currentProject.organizationDisplayName} | Alerts`

        this.projects = []
        this.alerts = []
        this.filteredAlerts = []
        this.query = ''
        this.filteredProjects = [] // Used to limit list when a search query is entered
        this.editingLocation = false
        this.projectBeingEdited = null
        this.timeZoneToBeConfirmed = null
        this.loadingTimeZone = false
        this.readyToSaveLocation = false

        this.compactAlertView = true

        this.states = {
            ACTIVE_ALERTS: 'ACTIVE_ALERTS',
            LOG: 'LOG',
        }

        this.selectedProject = null

        this.activeState = this.states.ACTIVE_ALERTS

        this.filters = [];
        this.filterCount = 0;
        this.showFilters = false;
            
        const projectPromises = []
        projectPromises.push(this.fetchAllProjects().then(projects => {
            this.projects = [...projects]
        }))
        
        if (!this.ProjectManager.currentProject.organizationId) {
            this.ProjectManager.currentProject.organizationId = this.ProjectManager.currentProject.organization.split('/')[1]
        }

        const listActiveAlertsPromise = this.AlertsAndRulesService.listActiveOrganizationAlerts().then(alerts => {
            alerts.forEach(alert => {
                alert.showRangeDropdown = false;
                alert.currentRange = RANGE_LABEL_WEEK;
                alert.triggered = alert.events.find(event => event.type === ALERT_EVENT_TYPES.TRIGGERED).triggered;
                alert.triggerDescription = triggerDescription(alert);

                alert.events.sort((a, b) => {
                    return new Date(b.createTime) - new Date(a.createTime);
                });
            });
            this.alerts = alerts;
            this.filteredAlerts = alerts;
        });

        Promise.all([...projectPromises, listActiveAlertsPromise]).then(() => {
            
            this.projects.forEach(project => {
                project.alerts = this.alerts.filter(alert => alert.project === project.name)
                project.alerts.sort((a, b) => {
                    return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
                });
                project.loadedDevices = false
            });

            // Sort projects based on the number of alerts, then by ABC
            this.projects.sort((a, b) => {
                if (a.alerts.length > b.alerts.length) return -1
                if (a.alerts.length < b.alerts.length) return 1
                if (a.displayName < b.displayName) return -1
                if (a.displayName > b.displayName) return 1
                return 0
            });
            
            this.filteredProjects = this.projects

            this.selectedProject = this.projects[0]

            const deviceIds = this.selectedProject.alerts.map(alert => alert.device.split('/')[3]);

            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                this.selectedProject.alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.device = device;
                });
                this.selectedProject.loadedDevices = true
            });

            let initialLocation = [0, 35]
            let initialZoom = 0.8
            if (this.selectedProject.location.latitude !== null && this.selectedProject.location.longitude !== null) {
                initialLocation = [this.selectedProject.location.longitude, this.selectedProject.location.latitude]
                initialZoom = 4.5
            }
            

            mapboxgl.accessToken = 'pk.eyJ1IjoidG9yZC1kdCIsImEiOiJjbHgxbWtvOGcwZXRuMmlzYXBvemQwNG9yIn0.bxWUKCEJfN7LbQQqji6PSA'; // eslint-disable-line
            this.map = new mapboxgl.Map({ // eslint-disable-line
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v12',
                center: initialLocation,
                zoom: initialZoom,
                projection: 'mercator' // Flat style
            });

            this.map.on('load', () => {
                this.updateMap()
                this.setupMapListeners()

                this.showPopup(this.selectedProject.id)

                // Trick to ensure map is rendered correctly
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 50);

                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 200);
            })    

            this.loadedAlerts = true;
            this.$scope.$applyAsync();
        }).catch(error => {
            console.error('An error occurred:', error); // eslint-disable-line no-console
        });
    }

    setState(state) {
        this.activeState = state
    }

    showFilterOptions() {
        this.setupFilters()
        this.showFilters = !this.showFilters

    }

    clearAllOptions(filter) {
        filter.options.forEach(option => {
            option.active = false;
        });
        this.updatedFilters();
    }

    setupFilters() {
        
        if (this.filters.length === 0) {
            this.filters.push(
                {
                    displayName: "Alert Type",
                    active: false,
                    options: [
                        {
                            displayName: "Sensor Offline",
                            value: "SENSOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Cloud Connector Offline",
                            value: "CLOUD_CONNECTOR_OFFLINE",
                            active: true
                        },
                        {
                            displayName: "Temperature",
                            value: "temperature",
                            active: true
                        },
                        {
                            displayName: "Humidity",
                            value: "relativeHumidity",
                            active: true
                        },
                        {
                            displayName: "CO2",
                            value: "co2",
                            active: true
                        },
                        {
                            displayName: "Door & Window",
                            value: "contact",
                            active: true
                        },
                        {
                            displayName: "Proximity",
                            value: "objectPresent",
                            active: true
                        },
                        {
                            displayName: "Water Detected",
                            value: "waterPresent",
                            active: true
                        },
                    ],
                },
                {
                    displayName: "Alert Status",
                    active: false,
                    options: [
                        {
                            displayName: "Open",
                            value: "TRIGGERED",
                            active: true
                        },
                        {
                            displayName: "Acknowledged",
                            value: "ACKNOWLEDGED",
                            active: true
                        },
                        {
                            displayName: "Monitoring",
                            value: "MONITORING",
                            active: true
                        }
                    ],
                },
                // {
                //     displayName: "Device Labels",
                //     active: false,
                //     dropdown: true,
                //     options: [],
                //     optionValue: "",
                //     disabled: true
                // },
                // {
                //     displayName: "Duration",
                //     active: false,
                //     dropdown: true,
                //     options: [],
                //     optionValue: "",
                //     disabled: true
                // },
                // {
                //     displayName: "Acknowledged",
                //     active: false,
                //     dropdown: true,
                //     options: [
                //         {
                //             displayValue: "Has been acknowledged",
                //             value: "On"
                //         },
                //         {
                //             displayValue: "Has not been acknowledged",
                //             value: "Off"
                //         }
                //     ],
                //     optionValue: "On",
                //     disabled: true
                // },
                // {
                //     displayName: "Corrective Action",
                //     active: false,
                //     dropdown: true,
                //     options: [
                //         {
                //             displayValue: "Has corrective action",
                //             value: "On"
                //         },
                //         {
                //             displayValue: "No corrective action",
                //             value: "Off"
                //         }
                //     ],
                //     optionValue: "On",
                //     disabled: true
                // },
            )
        }
    }

    

    updatedFilters() {
        this.updateCount()
        // Check if any filter optionValues are null, if so, don't update
        // This is caused by the <select> element being reset when the element is removed from the DOM
        if (this.filters.filter(filter => filter.optionValue === null).length === 0) {
            this.updateFilteredAlerts()
        }
    }

    updateCount() {
        let count = 0
        Object.keys(this.filters).forEach(key => {
            if (this.filters[key].active) {
                count += 1
            }
        });
        this.filterCount = count
    }

    updateFilteredAlerts() {
        
        
        this.filteredAlerts = this.alerts
        this.filters.forEach(filter => {
            if (filter.active) {
                if (filter.displayName === 'Alert Status') {
                    this.filteredAlerts = this.filteredAlerts.filter(alert => {
                        return filter.options.some(option => {
                            return alert.status === option.value && option.active
                        })
                    })
                }
                if (filter.displayName === 'Alert Type') {
                    this.filteredAlerts = this.filteredAlerts.filter(alert => {
                        return filter.options.some(option => {
                            if (option.value === 'SENSOR_OFFLINE') {
                                return alert.triggered.trigger.field === 'connectionStatus' && alert.triggered.trigger.connection === 'SENSOR_OFFLINE' && option.active
                            }
                            if (option.value === 'CLOUD_CONNECTOR_OFFLINE') {
                                return alert.triggered.trigger.field === 'connectionStatus' && alert.triggered.trigger.connection === 'CLOUD_CONNECTOR_OFFLINE' && option.active
                            }
                            return alert.triggered.trigger.field === option.value && option.active
                        })
                    })
                    
                }
            }
        })
        
        this.projects.forEach(project => {
            project.alerts = this.filteredAlerts.filter(alert => alert.project === project.name)
            project.alerts.sort((a, b) => {
                return new Date(b.resolveTime || b.archivedTime) - new Date(a.resolveTime || a.archivedTime);
            });
        });

        // Sort projects based on the number of alerts, then by ABC
        this.projects.sort((a, b) => {
            if (a.alerts.length > b.alerts.length) return -1
            if (a.alerts.length < b.alerts.length) return 1
            if (a.displayName < b.displayName) return -1
            if (a.displayName > b.displayName) return 1
            return 0
        });

        this.removePopup()
        this.updateMap()
    }

    clearFilters() {
        this.showFilters = false
        this.filters = []
        this.filterCount = 0
        this.filteredAlerts = this.alerts
        this.updateFilteredAlerts()
    }

    selectProject(project) {
        this.selectedProject = project
        this.popup.remove();

        if (!this.selectedProject.loadedDevices) {
            const deviceIds = this.selectedProject.alerts.map(alert => alert.device.split('/')[3]);
            this.SensorService.getFromCache(deviceIds).then(devicesResponse => {
                const devices = devicesResponse.devices;
                this.selectedProject.alerts.forEach(alert => {
                    const device = devices.find(d => d.name === alert.device);
                    alert.device = device;
                });
                this.selectedProject.loadedDevices = true
            });
        }

        // Check if project has a location
        if (project.location.latitude !== null && project.location.longitude !== null) {
            this.showPopup(project.id)
        } else {
            // Reset map
            this.popup.remove();
            this.marker.remove();
            this.map.flyTo({ center: [0, 35], zoom: 0.8 });
        }

        const elementInProjectList = document.getElementById(project.id)
        if (elementInProjectList) {
            elementInProjectList.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    alertIcon(alert) {
        let iconName = alert.triggered.trigger.field
        if (iconName === 'relativeHumidity') {
            iconName = 'humidity'
        }
        if (iconName === 'connectionStatus') {
            iconName = alert.device.typeIcon
        }
        if (iconName === 'objectPresent') {
            return 'proximity'
        }
        if (iconName === 'waterPresent') {
            return 'waterDetector'
        }
        return iconName
    }

    getAlertStatusHeader(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return 'Open - Action Needed'
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                const acknowledgedBy = event.acknowledged.account.displayName || event.acknowledged.account.email // eslint-disable-line
                return `Acknowledged by ${acknowledgedBy}`
            case 'MONITORING':
                return 'Monitoring'
            default:
                return alert.status
        }
    }

    getAlertStatusDescription(alert) {
        switch (alert.status) {
            case 'TRIGGERED':
                return `${moment(alert.createTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.updateTime).fromNow()})`
            case 'ACKNOWLEDGED':
                const event = alert.events.find(e => e.type === 'ACKNOWLEDGED') // eslint-disable-line
                return `${moment(event.createTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(event.createTime).fromNow()})`
            case 'MONITORING':
                return `${moment(alert.updateTime).format(`MMM D, ${getHoursMinutesFormat()}`)} (${moment(alert.updateTime).fromNow()})`
            default:
                return ''
        }
    }


    getDuration(alert) {
        const createTime = moment(alert.createTime)
        const duration = moment.duration(moment().diff(createTime))
        let humanizedDuration = moment.duration(duration).humanize();
        if (humanizedDuration === 'a few seconds') {
            humanizedDuration = '< 1 min';
        }
        return humanizedDuration.replace('minutes', 'min');
    }

    numberOfOpenAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'TRIGGERED').length;
    }

    numberOfAcknowledgedAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'ACKNOWLEDGED').length;
    }

    numberOfMonitoringAlertsForProject(project) {
        return project.alerts.filter(alert => alert.status === 'MONITORING').length;
    }

    getNumberOfUserInteractions(alert) {
        // Get the number of user interactions (acknowledgements, corrective actions, comments)
        return alert.events.filter(event => {
            return event.type === ALERT_EVENT_TYPES.ACKNOWLEDGED || 
                   event.type === ALERT_EVENT_TYPES.CORRECTIVE_ACTION || 
                   event.type === ALERT_EVENT_TYPES.COMMENT;
        }).length;
    }


    exitAlertOverview() {
        this.$state.go(States.SENSORS);
    }

    $onDestroy() {
        this.$rootScope.$broadcast(HIDE_ORGANIZATION_ALERTS);
    }

    setupMapListeners() {
        this.isMouseOverMarkerOrPopup = false;
    
        // Initialize the popup if not already done
        if (!this.popup) {
            this.popup = new mapboxgl.Popup({ // eslint-disable-line
                closeButton: false,
                closeOnClick: false
            });
        }
    
        // Initialize the marker if not already done
        if (!this.marker) {
            this.marker = new mapboxgl.Marker(); // eslint-disable-line
        }
    
        // Event handler for mouseenter on points
        const onMouseEnter = (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
            const coordinates = e.features[0].geometry.coordinates.slice();
    
            // Ensure the popup appears over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            const project = this.projects.find(p => p.id === e.features[0].properties.id);

            this.popup
                .setLngLat(coordinates)
                .setHTML(this.popupHtmlContent(project))
                .addTo(this.map);
    
            this.isMouseOverMarkerOrPopup = true;
    
            // Add event listeners to popup content to handle mouse enter/leave
            const popupContent = document.querySelector('.mapboxgl-popup-content');
            popupContent.addEventListener('mouseenter', () => {
                this.isMouseOverMarkerOrPopup = true;
            });
            popupContent.addEventListener('mouseleave', () => {
                this.isMouseOverMarkerOrPopup = false;
                setTimeout(() => {
                    if (!this.isMouseOverMarkerOrPopup) {
                        this.popup.remove();
                    }
                }, 300);
            });
        };
    
        // Event handler for mouseleave on points
        const onMouseLeave = () => {
            this.map.getCanvas().style.cursor = '';
            this.isMouseOverMarkerOrPopup = false;
            setTimeout(() => {
                if (!this.isMouseOverMarkerOrPopup) {
                    this.popup.remove();
                }
            }, 300);
        };
    
        // Event handler for click on points
        const onClickPoint = (e) => {
            const project = this.projects.find(p => p.id === e.features[0].properties.id);
            this.selectProject(project);
            this.$scope.$applyAsync();
        };
    
        // Bind the event handlers to the class instance to maintain context
        this.onMouseEnter = onMouseEnter.bind(this);
        this.onMouseLeave = onMouseLeave.bind(this);
        this.onClickPoint = onClickPoint.bind(this);
    
        // Attach event listeners to 'alert-points' layer
        this.map.on('mouseenter', 'alert-points', this.onMouseEnter);
        this.map.on('mouseleave', 'alert-points', this.onMouseLeave);
        this.map.on('click', 'alert-points', this.onClickPoint);
    
        // Attach event listeners to 'no-alert-points' layer
        this.map.on('mouseenter', 'no-alert-points', this.onMouseEnter);
        this.map.on('mouseleave', 'no-alert-points', this.onMouseLeave);
        this.map.on('click', 'no-alert-points', this.onClickPoint);
    
        // Handle click events on the map for editing location
        this.map.on('click', (e) => {
            if (!this.editingLocation) return;
    
            this.getTimeZone(e.lngLat);
    
            // Remove existing marker if it exists
            if (this.marker) {
                this.marker.remove();
            }
    
            // Place a new marker on the map
            this.marker = new mapboxgl.Marker() // eslint-disable-line
                .setLngLat([e.lngLat.lng, e.lngLat.lat])
                .setDraggable(false)
                .addTo(this.map);
        });
    
        // Add Mapbox GL Geocoder for search functionality
        if (!this.geocoder) {
            this.geocoder = new MapboxGeocoder({ // eslint-disable-line
                accessToken: mapboxgl.accessToken, // eslint-disable-line
                mapboxgl: mapboxgl, // eslint-disable-line
                marker: false  // Disable the default marker
            });
    
            this.map.addControl(this.geocoder);
    
            this.geocoder.on('result', (e) => {
                const lngLat = e.result.center;
                this.map.flyTo({ center: lngLat, zoom: 15 });
            });
        }
    }
    

    updateMap() {
        // Create locations array
        const locations = [];
        this.projects.forEach(project => {
            if (project.location.latitude === null || project.location.longitude === null) return;
    
            const hasAlerts = project.alerts && project.alerts.length > 0;
    
            locations.push({
                coords: [project.location.longitude, project.location.latitude],
                name: project.displayName,
                description: `Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b>`,
                id: project.id,
                hasAlerts: hasAlerts,
                sortKey: hasAlerts ? 1 : 0
            });
        });
    
        // Create GeoJSON features
        this.allFeatures = locations.map(loc => ({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: loc.coords },
            properties: { 
                name: loc.name, 
                description: loc.description, 
                id: loc.id,
                hasAlerts: loc.hasAlerts,
                sortKey: loc.sortKey
            }
        }));
    
        const geojson = {
            type: 'FeatureCollection',
            features: this.allFeatures
        };
    
        // Remove existing layers and sources if they exist
        if (this.map.getSource('markers')) {
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            this.map.removeSource('markers');
        }
    
        this.map.addSource('markers', {
            type: 'geojson',
            data: geojson,
        });
    
        // Create the pulsing dot
        const size = 100;
        const pulsingDot = {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4), // 4 channels: R, G, B, A
    
            // onAdd method receives the map instance
            onAdd: function (map) {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
    
                this.map = map; // Assign the map instance to this.map
            },
    
            render: function () {
                const duration = 2600;
                const t = (performance.now() % duration) / duration;
    
                const radius = (size / 2) * 0.3;
                const outerRadius = (size / 2) * 0.4 * t + radius;
                const context = this.context;
    
                // Clear canvas
                context.clearRect(0, 0, this.width, this.height);
    
                // Draw outer circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(220, 110, 93, ${1 - t})`;
                context.fill();
    
                // Draw inner circle
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = 'rgba(220, 110, 93, 1)';
                context.strokeStyle = '#fff';
                context.lineWidth = 3;
                context.fill();
                context.stroke();
    
                // Update the image data
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;
    
                // Trigger a repaint of the map
                this.map.triggerRepaint();
    
                // Return true to indicate that the image has been updated
                return true;
            }
        };
    
        // Add the pulsing dot image to the map
        if (!this.map.hasImage('pulsing-dot')) {
            this.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
        }
    
        // Add Layers

        // Add circle layer for non-alert points
        this.map.addLayer({
            id: 'no-alert-points',
            type: 'circle',
            source: 'markers',
            filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['==', ['get', 'hasAlerts'], false]
            ],
            paint: {
                'circle-color': '#5F9B55',
                'circle-radius': 6,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
            }
        });
    
        // Add symbol layer for alert points
        this.map.addLayer({
            id: 'alert-points',
            type: 'symbol',
            source: 'markers',
            filter: [
                'all',
                ['!', ['has', 'point_count']],
                ['==', ['get', 'hasAlerts'], true]
            ],
            layout: {
                'icon-image': 'pulsing-dot',
                'icon-size': 1,
                'icon-allow-overlap': true
            }
        });
    
        // Initialize the popup
        this.popup = new mapboxgl.Popup({ // eslint-disable-line
            closeButton: false,
            closeOnClick: false
        });
    
        this.$scope.$applyAsync();
    }
    
    

    queryChanged() {
        if (this.query === '') {
            this.filteredProjects = this.projects;
            return;
        }
        this.filteredProjects = this.projects.filter(project => project.displayName.toLowerCase().includes(this.query.toLowerCase()));
    }

    clearQuery() {
        this.query = '';
        this.queryChanged();
    }

    showPopup(projectId) {
        // Trigger popup based on project name
        const feature = this.allFeatures.find(f => f.properties.id === projectId);
        if (feature) {
            this.map.flyTo({
                duration: 800,
                center: feature.geometry.coordinates,
                zoom: this.map.getZoom() > 4.5 ? this.map.getZoom() : 4.5
            });
            const project = this.projects.find(p => p.id === projectId);
            setTimeout(() => {
                this.popup
                    .setLngLat(feature.geometry.coordinates)
                    .setHTML(this.popupHtmlContent(project))
                    .addTo(this.map);   
            }, 400);
        }
    }

    // Generate dynamic HTML content for the popup
    popupHtmlContent(project) {
        const openAlertsHtml = this.numberOfOpenAlertsForProject(project) === 0 ? '' :
            `<span>
                <svg width="12px" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82193 1.31733L10.3439 8.36057C10.4566 8.586 10.5099 8.83643 10.4985 9.08822C10.4872 9.34 10.4117 9.58471 10.2791 9.79907C10.1466 10.0134 9.9615 10.1904 9.74136 10.3131C9.52121 10.4357 9.27336 10.5001 9.02129 10.5H1.97806C1.72609 10.5 1.4783 10.4356 1.25823 10.3129C1.03816 10.1901 0.853121 10.0131 0.720671 9.79879C0.588228 9.58443 0.512778 9.33979 0.501485 9.08807C0.490193 8.83636 0.543435 8.58593 0.656157 8.36057L4.1775 1.31733C4.30021 1.07174 4.48893 0.865185 4.7225 0.720828C4.956 0.576464 5.22514 0.5 5.49971 0.5C5.77421 0.5 6.04336 0.576464 6.27693 0.720828C6.51043 0.865185 6.69914 1.07174 6.82193 1.31733ZM5.5 7.06243C5.25136 7.06243 5.01293 7.16122 4.83707 7.337C4.66129 7.51286 4.5625 7.75129 4.5625 7.99993C4.5625 8.24857 4.66129 8.48707 4.83707 8.66286C5.01293 8.83872 5.25136 8.93743 5.5 8.93743C5.74864 8.93743 5.98707 8.83872 6.16293 8.66286C6.33871 8.48707 6.4375 8.24857 6.4375 7.99993C6.4375 7.75129 6.33871 7.51286 6.16293 7.337C5.98707 7.16122 5.74864 7.06243 5.5 7.06243ZM5.5 6.12493C5.845 6.12493 6.125 5.92493 6.125 5.67865V3.44611C6.125 3.19986 5.845 2.99986 5.5 2.99986C5.155 2.99986 4.875 3.19986 4.875 3.44611V5.67865C4.875 5.92493 5.155 6.12493 5.5 6.12493Z" fill="#DC724A" style="fill:#DC724A;fill:color(display-p3 0.8627 0.4471 0.2902);fill-opacity:1;"></path>
                </svg>
                ${this.numberOfOpenAlertsForProject(project)} Open
            </span>`

        const acknowledgeAlertsHtml = this.numberOfAcknowledgedAlertsForProject(project) === 0 ? '' :
            `<span>
                <svg width="12px" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.3125 6C0.3125 2.85875 2.85875 0.3125 6 0.3125C9.14125 0.3125 11.6875 2.85875 11.6875 6C11.6875 9.14125 9.14125 11.6875 6 11.6875C2.85875 11.6875 0.3125 9.14125 0.3125 6ZM8.10583 4.94183C8.14083 4.8952 8.16616 4.84203 8.18032 4.78547C8.19448 4.72891 8.19719 4.67008 8.18829 4.61246C8.17939 4.55483 8.15906 4.49956 8.1285 4.44991C8.09793 4.40025 8.05775 4.3572 8.01032 4.3233C7.96288 4.28939 7.90914 4.26531 7.85226 4.25247C7.79539 4.23963 7.73652 4.23828 7.67911 4.24852C7.62171 4.25876 7.56693 4.28036 7.518 4.31207C7.46906 4.34378 7.42696 4.38495 7.39417 4.43317L5.5065 7.07567L4.55917 6.12833C4.47623 6.05105 4.36654 6.00898 4.2532 6.01098C4.13985 6.01298 4.03171 6.0589 3.95155 6.13905C3.8714 6.21921 3.82548 6.32735 3.82348 6.4407C3.82148 6.55404 3.86355 6.66373 3.94083 6.74667L5.25333 8.05917C5.29824 8.10404 5.35238 8.13861 5.41199 8.16046C5.4716 8.18231 5.53525 8.19093 5.59853 8.18571C5.6618 8.18049 5.72318 8.16157 5.77841 8.13025C5.83363 8.09893 5.88138 8.05596 5.91833 8.00433L8.10583 4.94183Z" fill="#EAC25D" style="fill:#EAC25D;fill:color(display-p3 0.9157 0.7593 0.3635);fill-opacity:1;"></path>
                </svg>
                ${this.numberOfAcknowledgedAlertsForProject(project)} Ack
            </span>`

        const monitoringAlertsHtml = this.numberOfMonitoringAlertsForProject(project) === 0 ? '' :
            `<span>
                <svg width="12px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <g clip-path="url(#clip0_3165_5461_cache4)">
                    <path d="M0.566406 3.97261C0.566406 2.33088 1.89729 1 3.53902 1H11.5938" fill="#73A6DF" style="fill:#73A6DF;fill:color(display-p3 0.4510 0.6510 0.8745);fill-opacity:1;"></path>
                    <path d="M3.53902 1C1.89729 1 0.566406 2.33088 0.566406 3.97261V10.4819C0.566406 12.1237 1.89729 13.4545 3.53902 13.4545H11.5938C13.2355 13.4545 14.5664 12.1237 14.5664 10.4819V3.97261C14.5664 2.33088 13.2355 1 11.5938 1H3.53902Z" fill="#73A6DF" style="fill:#73A6DF;fill:color(display-p3 0.4510 0.6510 0.8745);fill-opacity:1;"></path>
                    <path d="M3.75391 8.63632L6.29936 6.09086L8.12191 7.91341C8.9782 6.6842 10.0939 5.65799 11.3903 4.90723" stroke="white" style="stroke:white;stroke-opacity:1;" stroke-width="1.30052" stroke-linecap="round" stroke-linejoin="round"></path>
                    </g>
                    <defs>
                    <clipPath id="clip0_3165_5461_cache4">
                    <rect width="14" height="14" fill="white" style="fill:white;fill-opacity:1;" transform="translate(0.566406 0.219727)"></rect>
                    </clipPath>
                    </defs>
                </svg>
                ${this.numberOfMonitoringAlertsForProject(project)} Monitoring
            </span>`

        const noAlerts = project.alerts.length > 0 ? '' : 
            `<span>
                <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.996094 4.49805L3.62109 7.12305L7.99609 0.998047" style="stroke:rgb(53, 99, 44);stroke-opacity:1;" stroke-width="1.68845" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>                                                                 
                <span style="margin-left: 3px;">No Alerts</span>
            </span>`

        const noAlertsClass = project.alerts.length > 0 ? '' : 'no-alerts'

        return `
                <div class="popup-content">
                    <div class="popup-title">${project.displayName}</div>
                    <div class="popup-description">Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b></div>
                    <div style="border-top: 1px solid rgba(100, 100, 100, 0.1); padding-top: 12px; margin-top: 12px;">
                        <div class="alert-summary ${noAlertsClass}" style="justify-content: center;">
                            ${openAlertsHtml}
                            ${acknowledgeAlertsHtml}
                            ${monitoringAlertsHtml}
                            ${noAlerts}
                        </div>
                    </div>
                </div>
            `
    }

    removePopup() {
        if (this.popup) {
            this.popup.remove();
        }
    }

    getTimeZone(lngLat) {
        const lat = lngLat.lat;
        const lng = lngLat.lng;
        this.readyToSaveLocation = false
        this.loadingTimeZone = true
        this.$scope.$applyAsync()
  
        // Use the Mapbox Tilequery API to get the time zone information
        fetch(`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`) // eslint-disable-line
            .then(response => response.json())
            .then(data => {
                if (data && data.features && data.features.length > 0) {
                    const timeZoneFeature = data.features[0];
                    const timeZoneId = timeZoneFeature.properties.TZID;
                    this.timeZoneToBeConfirmed = timeZoneId
                    this.readyToSaveLocation = true
                } else {
                    this.marker.remove();
                    this.ToastService.showSimpleTranslated('project_no_valid_timezone_found');
                }
            })
            .catch(error => {
                console.error('Error fetching time zone:', error); // eslint-disable-line no-console
                this.ToastService.showSimpleTranslated('project_timezone_wasnt_updated');
            }).finally(() => {
                this.loadingTimeZone = false
                this.$rootScope.$applyAsync()
            })
    }

    editLocation(project) {
        // Make map ready for user to place a marker
        this.editingLocation = true;
        this.projectBeingEdited = project;
    
        // Ensure 'alert-points' is above 'no-alert-points'
        if (this.map.getLayer('alert-points')) {
            this.map.moveLayer('alert-points');
        }
    
        // Use the unique project ID to set opacity
        const projectId = project.id;
    
        // For 'alert-points' layer (symbol layer)
        if (this.map.getLayer('alert-points')) {
            this.map.setPaintProperty('alert-points', 'icon-opacity', [
                'case',
                ['==', ['get', 'id'], projectId], 0.5,
                1
            ]);
        }
    
        // For 'no-alert-points' layer (circle layer)
        if (this.map.getLayer('no-alert-points')) {
            this.map.setPaintProperty('no-alert-points', 'circle-opacity', [
                'case',
                ['==', ['get', 'id'], projectId], 0.5,
                1
            ]);
        }
    
        this.removePopup();
        this.readyToSaveLocation = false;
        this.showConfirmLocationDialog = true;
        this.$scope.$applyAsync();
    }
    

    confirmNewLocation() {
        // Save the new location
        const newLocation = this.marker.getLngLat();
        this.IAMService.updateProject(this.projectBeingEdited.name, { 
            location: { 
                timeLocation: this.timeZoneToBeConfirmed, 
                latitude: newLocation.lat, 
                longitude: newLocation.lng
            }
        }).then((updatedProject) => {
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects;
            this.queryChanged(); // Update in case there is an active query entered
    
            // Remove layers and source from map
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getLayer('clusters')) {
                this.map.removeLayer('clusters');
            }
            if (this.map.getLayer('cluster-count')) {
                this.map.removeLayer('cluster-count');
            }
            if (this.map.getSource('markers')) {
                this.map.removeSource('markers');
            }
            if (this.marker) {
                this.marker.remove();
            }
    
            // Update the map with the new data
            this.updateMap();
    
            // Reset editing state
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;
    
            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject(this.timeZoneToBeConfirmed);
            }
    
        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        });
    }
    

    cancelLocationEdit() {
        this.editingLocation = false;
        this.projectBeingEdited = null;
    
        // Remove the editing marker if it exists
        if (this.marker) {
            this.marker.remove();
        }
    
        // Reset opacity for all markers
        if (this.map.getLayer('alert-points')) {
            this.map.setPaintProperty('alert-points', 'icon-opacity', 1);
        }
        if (this.map.getLayer('no-alert-points')) {
            this.map.setPaintProperty('no-alert-points', 'circle-opacity', 1);
        }
    
        // Ensure 'alert-points' is above 'no-alert-points'
        if (this.map.getLayer('alert-points') && this.map.getLayer('no-alert-points')) {
            this.map.moveLayer('alert-points');
        }
    
        // Set visibility for layers
        if (this.map.getLayer('alert-points')) {
            this.map.setLayoutProperty('alert-points', 'visibility', 'visible');
        }
        if (this.map.getLayer('no-alert-points')) {
            this.map.setLayoutProperty('no-alert-points', 'visibility', 'visible');
        }
        if (this.map.getLayer('clusters')) {
            this.map.setLayoutProperty('clusters', 'visibility', 'visible');
        }
        if (this.map.getLayer('cluster-count')) {
            this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
        }
    
        this.showConfirmLocationDialog = false;
    }
    

    clearLocation() {
        this.IAMService.updateProject(this.projectBeingEdited.name, { 
            location: { 
                timeLocation: "", 
                latitude: null, 
                longitude: null
            }
        }).then((updatedProject) => {
            this.removePopup();
    
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects;
            this.queryChanged(); // Update in case there is an active query entered
    
            // Remove layers and source from the map
            if (this.map.getLayer('alert-points')) {
                this.map.removeLayer('alert-points');
            }
            if (this.map.getLayer('no-alert-points')) {
                this.map.removeLayer('no-alert-points');
            }
            if (this.map.getLayer('clusters')) {
                this.map.removeLayer('clusters');
            }
            if (this.map.getLayer('cluster-count')) {
                this.map.removeLayer('cluster-count');
            }
            if (this.map.getSource('markers')) {
                this.map.removeSource('markers');
            }
    
            // Update the map with the new data
            this.updateMap();
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;
    
            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject("");
            }
        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        });
    }

    updateTimeZoneForCurrentProject(timeZone) {
        moment.tz.setDefault(timeZone); // Update Moment timezone globally for Studio
        if (window.Highcharts) {
            window.Highcharts.setOptions({
                time: {
                    useUTC: true,
                    timezone: timeZone
                }
            })
        }

        this.$rootScope.$applyAsync();
        this.$rootScope.$broadcast('TIME_ZONE_CHANGED', { timeZone: timeZone });
    }
    
    openProject(projectId) {
        this.$state.go(States.SENSORS, {
            projectId: projectId
        });
    }

    fetchAllProjects() {
        const deferred = this.$q.defer();
        this.getProjectsPage(null, [])
            .then(projects => deferred.resolve(projects))
            .catch(() => {
                this.ToastService.showSimpleTranslated('projects_wasnt_fetched');
                deferred.reject();
            });
        return deferred.promise;
    }

    getProjectsPage(pageToken, previousData) {
        const requestParams = {
            organization: this.organization,
            pageToken
        }
        return this.IAMService
            .projects(requestParams)
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getProjectsPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    truncatedProjectDisplayName(displayName) {
        return truncateString(displayName, 30);
    }

    showTimeline(alert) {
        this.DialogService.show({
            controller: TimelineController,
            template: TimelineTemplate,
            controllerAs: '$ctrl',
            parent: document.body,
            clickOutsideToClose: true,
            escapeToClose: true,
            fullscreen: false,
            locals: {
                alert
            }
        })
    }

    goToDevice(device) {
        this.$state.go(States.SENSOR_DETAILS, {
            projectId: device.name.split('/')[1],
            sensorId: device.name.split('/')[3]
        });
    }

    goToAlertRule(alert) {
        this.$state.go(States.ALERTS_RULES_DETAIL, {
            projectId: alert.project.split('/')[1],
            ruleId: alert.rule.split('/')[3]
        });
    }

    archiveAlert(alert) {
        this.AlertsAndRulesService.archiveAlert(alert)
            .then(() => {
                this.$state.reload()
            });
    }
}